<template>
  <NuxtLayout>
    <div :class="$style.root">
      <code class="font-size-default" v-if="isDev">{{ error }}</code>
      <div :class="$style.error" v-else>
        <span :class="$style.message">{{ $t('error.message') }}</span>&thinsp;
        <button @click="handleError" :class="$style.button">
          {{ $t('error.button') }}
        </button>
      </div>
    </div>
  </NuxtLayout>
</template>

<script setup lang="ts">
const runtimeConfig = useRuntimeConfig()

const props = defineProps({
  error: Object
})

const handleError = () => clearError({ redirect: '/' })

const isDev = runtimeConfig?.public?.env === 'development'

useHead({
  htmlAttrs: {
    'data-page-type': 'error'
  }
})

const error = computed(() => props.error)
watch(error, (error) => {
  if (error) {
    console.error(error)
  }
})

if (!isDev) {
  useBugsnag().notify('Error page', props.error)
}
</script>

<style module>
.root {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.error {
  composes: container font-size-x-large from global;
  text-align: center;
}

.message {
}

.button {
  composes: reset-button link from global;
  text-transform: lowercase;
}
</style>
